import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { filter, interval, take } from 'rxjs';
import { DOCUMENT, JsonPipe, NgIf } from '@angular/common';
import * as signalR from '@microsoft/signalr';
import { environment } from '../environments/environment';
import { isNullOrEmpty } from '@external-system/modules/shared/utils';
import { FooterComponent } from '@external-system/modules/shared/ui';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgIf,
    JsonPipe,
    FooterComponent,
    MatTooltipModule,
  ],
  selector: 'external-system-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'external-system';
  username = '';
  isAdmin = false;

  private connection: signalR.HubConnection | undefined;

  public messages: any[] = [];

  constructor(
    private readonly keycloak: KeycloakService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    //this.initWebSocket();

    interval(1000) // Prüfung alle 1000ms = 1 Sekunde
      .pipe(filter(() => this.keycloak.getKeycloakInstance() !== null))
      .pipe(take(1))
      .subscribe(() => {
        this.username = this.keycloak.getUsername();
        this.isAdmin = this.keycloak
          .getUserRoles()
          .includes('eks_entitlement_admin');
        if (this.isAdmin) {
          this.renderer.addClass(this.document.body, 'isAdmin');
        }
      });

    this.keycloak.keycloakEvents$.subscribe((event) => {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        this.keycloak.updateToken(5).then();
      }
      if (event.type == KeycloakEventType.OnAuthRefreshError) {
        window.location.reload();
      }
    });
  }

  logout(): void {
    this.keycloak.logout().then();
  }

  initWebSocket() {
    this.keycloak.getToken().then();
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(environment.apiUrl + '/socket', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => this.keycloak.getToken(),
        withCredentials: true,
      })
      .build();
    this.connection
      .start()
      .then(() => console.info('Connection started'))
      .catch((err) => console.error('Error while starting connection: ' + err));

    this.connection.on('messageReceived', (from: string, body: string) => {
      this.messages.push({ from, body });
    });

    this.connection.on('userJoined', (user) => {
      this.messages.push({ from: '> ', body: user + ' joined' });
    });

    this.connection.on('userLeft', (user) => {
      this.messages.push({ from: '! ', body: user + ' has left!' });
    });
  }

  getUserName(): string {
    let user = this.keycloak.getUsername() ?? '';
    const profile = this.keycloak.getKeycloakInstance().profile;
    if (
      profile != undefined &&
      (!isNullOrEmpty(profile.firstName) || !isNullOrEmpty(profile.lastName))
    ) {
      user = profile.firstName + ' ' + profile.lastName;
    }
    if (user == '' && profile != undefined && !isNullOrEmpty(profile.email)) {
      user = profile.email ?? '';
    }

    if (isNullOrEmpty(user) && profile != undefined) {
      user = profile.id ?? '';
    }

    if (isNullOrEmpty(user)) {
      user = 'Benutzer';
    }

    return user;
  }

  toggleSidebar() {
    document.body.classList.toggle('page-sidebar-in');
  }
}
